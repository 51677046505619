import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import ToggleButton from "components/ToggleButton/ToggleButton";
import { PadSection } from "components/Section/Section";
import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";

const Wrapper = styled(PadSection)`
  position: relative;
  color: white;
  margin: 0 0 30px;

  ${media.medium`
    height: 500px;
    margin: 0;
  `}
`

const CustomGrid = styled(Grid)`
  grid-row-gap: 0px;
`

const Title = styled.h2`
${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 400;
  margin: 0 0 40px;

  ${media.medium`
    margin: 0 0 3vw;
  `}
`

const Col = styled.div`
  grid-column: 1 / span 6;

  ${media.medium`
    grid-column: span 3;
  `}
  
`

const FirstCol = styled(Col)`

  ${media.medium`
    grid-column: 3 / span 3;
  `}
`



const ModuleView = styled.div`
  overflow: hidden;
  max-height: 0px;
`

const Head = styled.div`
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1;
  padding: 18px 0;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  ${media.medium`
    grid-column: 3 / span 3;
    padding: 1vw 0;
    line-height: 1.33;
  `}
`

const Label = styled.div`
`

const ToggleWrapper = styled.div`
  position: relative;
  width: 30px;

  ${media.medium`
    width: 2.2vw;
  `}
`

const Points = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 0 30px;

  ${media.medium`
    padding: 2vw 0 1vw;
  `}
`

const Point = styled.li`
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 400;
  line-height: 1.33;

  &:before {
    content: "-";
    display: inline-block;
    padding: 0 10px 0 0;
    width: 10px;
  }
`

const Module = styled.div`
  position: relative;
  width: 100%;
  border-top: 1.5px solid #fff;


  ${({last}) => last && `
    border-bottom: 1.5px solid #fff;
  `}

  ${media.medium`
    border-bottom: 1.5px solid #fff;
  `}  


  ${({active}) => active && `

    & ${ModuleView} {
      max-height: 1000px;
    }

    & ${Head} {
      color: #807F7F;
    }
  `}
`

const AboutOutcomes = ({nodes}) => {

  const [ Active, setActive ] = React.useState(null);


  const onClick = (index) => {

    if(index !== Active) {
      setActive(index);
    } else {
      setActive(null);
    }

    ScrollTrigger.refresh(true);
  }


  return (
    <Wrapper>
      <Grid>
        <FirstCol>
          <Title>Outcomes</Title>
        </FirstCol>
      </Grid>
      <CustomGrid>  
        <FirstCol>
          <Module active={Active === 0}>
            <Head onClick={()=>onClick(0)}> 
              <Label>
              01. Brand Messaging
              </Label>
              <ToggleWrapper>
               <ToggleButton active={Active === 0}/>
              </ToggleWrapper>
            </Head>
            <ModuleView>
              <Points>
                { nodes.brand && nodes.brand.map((point,i) => {
                  return <Point key={`brandpoint-${i}`}>
                  { point }
                </Point>
                })}
              </Points>
            </ModuleView>
          </Module>
          
        </FirstCol>
        <Col>
          <Module active={Active === 1}>
            <Head onClick={()=>onClick(1)}>
              <Label>
              02. Creative Ideation
              </Label>
              <ToggleWrapper>
               <ToggleButton active={Active === 1}/>
              </ToggleWrapper>
            </Head>
            <ModuleView>
              <Points>
                { nodes.creative && nodes.creative.map((point,i) => {
                  return <Point key={`creativepoint-${i}`}>
                  { point }
                </Point>
                })}
              </Points>
            </ModuleView>
          </Module>
          
        </Col>
        <Col>
          <Module active={Active === 2} last>
            <Head onClick={()=>onClick(2)}>
              <Label>
              03. Visualisation
              </Label>
              <ToggleWrapper>
               <ToggleButton active={Active === 2}/>
              </ToggleWrapper>
            </Head>
            <ModuleView>
              <Points>
                { nodes.visualisation && nodes.visualisation.map((point,i) => {
                  return <Point key={`visualpoint-${i}`}>
                  { point }
                </Point>
                })}
              </Points>
            </ModuleView>
          </Module>
        </Col>
      </CustomGrid>
    </Wrapper>
  )
}

export default AboutOutcomes;
