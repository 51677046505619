import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import GlowButton from "components/GlowButton/GlowButton";
import ToggleButton from "components/ToggleButton/ToggleButton";
import { SectionMargin, PadSection } from "components/Section/Section";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";
import { SplitText, ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";

const Wrapper = styled(PadSection)`
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 15vw;
`;

const BigCopyCol = styled.div`
  grid-column: 1 / span 6;
  position: relative;
  overflow: hidden;
  ${media.medium`
    grid-column: 3 / span 8;
  `}
`;

const BorderCol = styled.div`
  grid-column: 1 / span 6;
  height: 1px;
  background: white;
  display: none;

  ${media.medium`
    display: block;
    grid-column: 2 / span 10;
  `}
`

const Title = styled.h2`
${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 400;
  margin: 0 0 40px;
  color: white;

  ${media.medium`
    margin: 0 0 10vw;
    font-size: var(--font-size-md-plus);
    line-height: 1.08;
    text-align: center;
  `}
  
`


const BigCopy = styled.div`
  ${fontstack.default}
  font-weight: 400;
  margin: 0 0 90px;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;

  ${media.medium`
    margin: 4vw 0 15vw;
  `}
`

const ChartInner = styled.div`
  grid-column: 2 / span 10;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 20px;
  border-top: 1px solid #FFF;
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.33;
  color: white;
`

const Region = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  ${media.medium`
    justify-content: space-between;
    height: auto;
  `}
`

const DesktopRegionLabel = styled.div`
  display: none;
  ${media.medium`
    display: block;
  `}
`

const MobileRegionLabel = styled.div`
  display: block;
  color: #807F7F;
  ${media.medium`
    display: none;
  `}
`

const MainCopy = styled.div` 
  padding: 20px 0 0;
`;

const ToggleWrapper = styled.div`
  position: relative;
  width: 30px;
  ${media.medium`
    width: 2.2vw;
  `}
`


const JobsCol = styled.div`
  grid-column: 1 / span 6;
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1;
  color: white;

  ${media.medium`
    display: block;
    grid-column: 3 / span 8;
    line-height: 1.33;
  `}
`;

const JobsDescription = styled.div`
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.33;
  color: white;
  margin: 0 0 50px;
  ${media.medium`
    margin: 0;
  `}
`



const JobHead = styled.div`
  padding: 18px 0;
  cursor: pointer;

  ${media.medium`
    padding: 1vw 0;
  `}
`

const JobBody = styled.div`
  position: relative;
  max-height: 0;
  overflow: hidden;
`

const JobBodyInner = styled.div`
  margin: 32px 0 50px;

  ${media.medium`
    border-top: 1.5px solid #807F7F;
    margin: 2vw 0 4vw;
    padding: 1vw 0 0;
  `}
`


const JobGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;

  ${media.medium`
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 20px;
  `}
`

const JobLeftCol = styled.div`
  grid-column: span 3;
  ${media.medium`
    grid-column: 1 / span 4;
  `}
`

const JobRightCol = styled.div`
  grid-column: span 1;
  ${media.medium`
    grid-column: 6 / span 3;
  `}
`

const JobBottomLeftCol = styled.div`
  grid-column: span 8;
  ${media.medium`
    grid-column: 1 / span 4;
  `}
`

const JobBottomRightCol = styled.div`
  grid-column: span 8;
  ${media.medium`
    grid-column: 6 / span 3;
  `}
`


const Points = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const RequirementsTitle = styled.h5`
  ${fontstack.default}
  margin: 0 0 1vw;
  font-weight: 400;
  font-size: var(--font-size-base);
  line-height: 1.33;
`

const Point = styled.li`
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 400;
  line-height: 1.33;

  &:before {
    content: "-";
    display: inline-block;
    padding: 0 10px 0 0;
    width: 10px;
  }
`

const JobWrapper= styled.div` 
  border-top: 1.5px solid var(--brand-white);

  ${({active}) => active && `

    & ${JobBody} {
      max-height: 1000px;
    }
  `}
`

const GradientMask = styled.div`
  position: absolute;
  display: block;
  background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%);
  width: 100%;
  left: 0;
  height: 220%;
  top: 0px;
  transform: translate(0,-55%);
  z-index: 2;
  pointer-events: none;

  ${media.medium` 
    display: none;
  `}
`

const AboutJobs = ({smoother, nodes}) => {
  const [ Active, setActive ] = React.useState(null);

  React.useLayoutEffect(()=> {
    let mm = gsap.matchMedia();
    let tl = null;
    mm.add("(max-width: 899px)", () => {
        
      tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#job-gradient-mask",
            id: "job-mask-trigger",
            start: "center 80%",
            end: "+=600",
            scrub: true,
            markers: false
        }
      });
  
      tl
      .to('#job-gradient-mask', {
        duration: 1,
        y: "50%",
      })
    
      return () => { // optional
        const triggerInstance = ScrollTrigger.getById("job-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
      };

    })

    return () => {
      const triggerInstance = ScrollTrigger.getById("job-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
    }

  },[]);

  React.useLayoutEffect(()=> {
    let mm = gsap.matchMedia();
    if(smoother) {
      
      mm.add("(min-width: 900px)", () => {
        const split = new SplitText("#jobs-copy", { type: "words,chars" }); 
        const chars = split.chars;

        chars.forEach((char, i) => {
          smoother.effects(char, { speed: 1, lag: (i + 1) * 0.008 });
        });

        return () => { // optional
          split.revert();
        };

      })
    }

    return () => {
      mm.revert();
    }
  },[smoother]);

  const onClick = (index) => {
    if(index !== Active) {
      setActive(index);
    } else {
      setActive(null);
    }
    ScrollTrigger.refresh(true);
  }

  return (
    <Wrapper id="jobs-wrapper">
      <Grid>
        <BorderCol/>
      </Grid>
      <Grid>
        <BigCopyCol>
          <GradientMask id="job-gradient-mask"/>
          <BigCopy id="jobs-copy">We value the importance of growth and drive with every individual at REN.</BigCopy>
        </BigCopyCol>
      </Grid>
      <Title>Current Openings:</Title>
      <Grid id="jobs-list-wrapper">
        <JobsCol>
          { nodes && nodes.map((node,i) => {

            return (
              <JobWrapper active={Active === i} key={`job-${i}`}>
                <JobHead onClick={()=>onClick(i)}>
                  <JobGrid>
                    <JobLeftCol>
                      {node.role}
                      <MobileRegionLabel>{node.region}</MobileRegionLabel>
                    </JobLeftCol>
                    <JobRightCol>
                      <Region>
                        <DesktopRegionLabel>{node.region}</DesktopRegionLabel>
                        <ToggleWrapper>
                          <ToggleButton active={Active === i}/>
                        </ToggleWrapper>
                      </Region>
                    </JobRightCol>
                  </JobGrid>
                </JobHead>
                <JobBody>
                  <JobBodyInner>
                    <JobGrid>
                      <JobBottomLeftCol>
                        <JobsDescription>{node.description}</JobsDescription>
                      </JobBottomLeftCol>
                      <JobBottomRightCol>
                        <RequirementsTitle>Requirements</RequirementsTitle>
                        <Points>
                          {node.requirements && node.requirements.map((point,i) => {
                            return (
                            <Point key={`requirements-${i}`}>{point}</Point>
                            )
                          })}
                          
                        </Points>
                      </JobBottomRightCol>
                    </JobGrid>
                  </JobBodyInner>
                </JobBody>
              </JobWrapper>
            )
          })}
        </JobsCol>
      </Grid>
    </Wrapper>
  )
}

export default AboutJobs;
