import React from "react";
import styled from "styled-components";

const Outer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  position: relative;
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 100%;
`

const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  display: ${(props) => props.hide ? "none" : "flex"};
`

const Vertical = styled.div`
  width: 1px;
  height: 100%;
  background: white;
`

const Horizontal = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`


const ToggleButton = ({active}) => {

  return (
    <Outer>
      <Wrapper>
        <Inner> 
          <Layer hide={active}>
            <Vertical />
          </Layer>
          <Layer>
            <Horizontal />
          </Layer>
        </Inner>
      </Wrapper>
    </Outer>
  )
}

export default ToggleButton;
