import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import AboutIntro from "modules/AboutIntro/AboutIntro";
import AboutOutcomes from "modules/AboutOutcomes/AboutOutcomes";
import AboutUnconventional from "modules/AboutUnconventional/AboutUnconventional";
import AboutJobs from "modules/AboutJobs/AboutJobs";
import AboutProfiles from "modules/AboutProfiles/AboutProfiles";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    about: sanityAbout {
      profiles {
        profileImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        description
        email
        name
        role
      }
      outcomesBrand
      outcomesCreative
      outcomesVisualisation
      jobs {
        description
        region
        requirements
        role
      }
    }
  }
`;

const AboutPage = props => {
  const { data, errors } = props;
  const [ Smoother, setSmoother ] = React.useState(false);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const about = (data || {}).about;


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout setSmoother={setSmoother} activeItem={"about"}>
      <SEO title="About" description="Our teams bring together filmmakers and PhD scientists, artists and experts, to create beautiful stories that both persuade and explain." keywords={site.keywords} />
      <AboutIntro smoother={Smoother}/>
      <AboutUnconventional smoother={Smoother}/>
      <AboutOutcomes nodes={{
        brand: about.outcomesBrand,
        creative: about.outcomesCreative,
        visualisation: about.outcomesVisualisation
      }}/>
      <AboutProfiles nodes={about.profiles}/>
      <AboutJobs smoother={Smoother} nodes={about.jobs}/>
    </Layout>
  );
};

export default AboutPage;

