import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { SiteContext } from "context/site-context";
import MaskButton from "components/MaskButton/MaskButton";
import { SectionMargin, PadSection } from "components/Section/Section";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";
import { SplitText, ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";
import GrainImage from "./assets/rumble.png";

const MainWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 130px;

  ${media.medium`
    margin: 0 0 15vw;
  `}
`;

const GradientWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const GradientInner = styled.div` 
  position: absolute;
  width: 100%;
  height: calc(100% + 300px);
  transform: translate(0, -300px);
`

const Grain = styled.div`
  background: url(${(props) => props.src ? props.src : ''}) center center;
  background-size: 600px 600px;
  pointer-events: none;
  user-select: none;
  width: 110%;
  height: 110%;
  position: absolute;
  z-index: 1;
`

const Profiles = styled(PadSection)`
  position: relative;
  margin: 0 0 10vw;
`;

const ProfileInner = styled(motion.div)` 
  display: flex;
  flex-direction: column;

  ${media.medium`
    flex-direction: row;
  `}
`

const Profile = styled(motion.div)`
  display: flex;
  grid-column: span 6;
  margin: 0 0 40px;
  
  
  &:nth-child(even) > ${ProfileInner} {
    align-items: flex-end;
  }

  ${media.medium`
    margin: 0;
    grid-column: ${(props) => props.middle ? "4 / span 6" : props.last ? "7 / span 6"  : "1 / span 6"};

    &:nth-child(even) > ${ProfileInner} {
     align-items: flex-start;
    }
  `}
  
`

const ProfileMiddle = styled(Profile)` 
  grid-column: 4 / span 6;
`

const ProfileLast = styled(Profile)` 
  grid-column: 7 / span 6;
`

const ProfileName = styled.h3`
  ${fontstack.default}
  font-size: var(--font-size-md-plus);
  color: white;
  font-weight: 300;
  line-height: 1.16;
  margin: 24px 0 90px;

  ${media.medium`
    margin: 30px 0 5px;
    font-size: var(--font-size-md);
  `}
`

const ProfileRole = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-sm);
  color: white;
  text-transform: uppercase;
`;

const ProfileEmail = styled.a`
  color: #807F7F;
  ${fontstack.default}
  font-size: var(--font-size-base);
  line-height: 1;
  margin: 0 0 1.8vw;
  display: block;
  transition: color 0.2s ease;
  &:hover {
    color: #FFF;
  }
`

const ProfileSection = styled.div`
  padding: 0 14px;
  box-sizing: border-box;

  ${media.medium`
    padding: 0 20px;
  `}
`

const FirstSection = styled(ProfileSection)`
  flex: 1;
`

const ProfileDivider = styled.div`
  position: relative;
  height: 1px;
  width: calc(100% + 28px);
  left: -14px;
  margin: 20px 0;
  background: white;

  ${media.medium`
    width: calc(100% + 40px);
    left: -20px;
    margin: 1.8vw 0;
  `}
`

const ProfileImage = styled(GatsbyImage)`
  width: 50%;
  overflow: visible;

  ${media.medium`
    width: 50%;
  `}
`

const ProfileContent = styled.div`
  background: var(--brand-black);
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  ${media.medium`
    width: 50%;
  `}
`

const ProfileBio = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-sm);
  color: white;
  margin: 10px 0 5px;
`
const ButtonWrapper = styled.div`
  display: flex;
  padding: 25px 0 100px;
  grid-column: 1 / span 6;

  ${media.medium`
    padding: 0 0 10vw;
    grid-column: 9 / span 4;
  `}
`

const SectionNamePad = styled(PadSection)`
  position: relative;
  width: 100%;
  margin: 100px 0;

  ${media.medium`
    margin: 10vw 0 8vw;
  `}
`

const SectionName = styled.div`
  grid-column: 1 / span 6;
  ${fontstack.default}
  font-weight: 400;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  text-align: center;

  ${media.medium`
    grid-column: 1 / span 12;
  `}
`;

const AboutProfiles = ({nodes}) => {

  React.useEffect(() => {
    const random = gsap.utils.random(-100, -60, null, true);

    /*setInterval(() => {
      gsap.set("#grain", { x: random(), y: random()})
    }, 25)*/

    const tl = gsap.timeline({
      scrollTrigger: {
          trigger: "#gradient-wrapper",
          start: "top top",
          end: "bottom bottom",
          endTrigger: "#main-profile-wrapper",
          pin: true,
          pinSpacing: false,
          markers: false
      }
    });

    const tl2 = gsap.timeline({
      scrollTrigger: {
          trigger: "#main-profile-wrapper",
          start: "top top",
          end: "+=4000",
          scrub: true
      }
    });

    tl2.to("#gradient-inner", {
      y: 0,
    })

  }, [])

  return (
    <MainWrapper id="main-profile-wrapper">
      <GradientWrapper id="gradient-wrapper">
        <GradientInner id="gradient-inner">
          <StaticImage
            src="./assets/NK.jpg" 
            placeholder="blurred" 
            alt="background"
            layout="fullWidth"
            objectFit="cover"
            objectPosition="top"
            quality={100}
            style={{width: '100%', height: '100%'}} />
        </GradientInner> 
      </GradientWrapper>
      <SectionNamePad>
        <Grid>
          <SectionName>Who we are</SectionName>
        </Grid>
      </SectionNamePad>
      <Profiles>
        <Grid>
          { nodes && nodes.map((node,i) => {
            const imageObject = node.profileImage ? getImage(node.profileImage.asset) : null;
            return (
              <Profile
                key={`profile-${i}`}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                id={nodes.length - 1 === i ? "last-profile" : null }
                middle={i % 4 === 1 || i % 4 === 3 || false} 
                last={i % 4 === 2 || false}>
                <ProfileInner
                  transition={{ duration: 0.8 }}
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 1000 }
                  }}>
                  <ProfileImage  
                    image={imageObject}
                    placeholder="blurred" 
                    alt={`${node.name} portrait image`}
                    layout="fullWidth"/>
                    <ProfileContent>
                      <FirstSection>
                        <ProfileName>{node.name}</ProfileName>
                      </FirstSection>
                      <ProfileSection>
                        <ProfileBio>
                          {node.description}
                        </ProfileBio>
                        <ProfileDivider />
                        <ProfileRole>{node.role}</ProfileRole>
                        <ProfileEmail href={`mailto:${node.email}`}>{node.email}</ProfileEmail>
                      </ProfileSection>
                    </ProfileContent>
                </ProfileInner>
              </Profile>
            )
          })}
      
        </Grid>
      </Profiles>
      <PadSection>
        <Grid>
          <ButtonWrapper>
            <a href="mailto:hello@renstudios.com"><MaskButton noarrow sidecar>Join the team</MaskButton></a>
          </ButtonWrapper>
        </Grid>
      </PadSection>
    </MainWrapper>
  )
}

export default AboutProfiles;
