import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Grid from "components/Grid/Grid";
import { PadSection, SectionMargin } from "components/Section/Section";
import MaskButton from "components/MaskButton/MaskButton";
import { media } from "utils/mediaQuery";
import { gsap } from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";

const Wrapper = styled(PadSection)`
  position: relative;
  margin: 200px 0 60px;
  overflow: hidden;

  ${media.medium`
    margin: 10vw 0 0;
  `}
`;

const TeamsCol = styled.div`
  grid-column: 4 / span 4;  
`;

const Col = styled.div`
  grid-column: 1 / span 6;

  ${media.medium`
    grid-column: 3 / span 8;
  `}
`

const SmallCopy = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-md-small);
  color: white;
  margin: 0 0 40px;
`

const BigCopy = styled.div`
   ${fontstack.default}
  font-weight: 400;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  margin: 10vw 0 20vw;
  display: none;

  ${media.medium` 
    display: block;
    margin: 4vw 0 8vw;
  `}
`

const MobileBigCopy = styled(BigCopy)`
  display: block;
  ${media.medium` 
    display: none;
  `}

`

const BorderCol = styled.div`
  grid-column: 1 / span 6;
  height: 1px;
  background: white;

  ${media.medium`
    grid-column: 2 / span 10;
  `}
`

const ChartInner = styled.div`
  grid-column: 2 / span 10;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 20px;
  border-top: 1px solid #FFF;
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.33;
  color: white;
  margin: 0 0 12vw;
`

const MainCopy = styled.div` 
  padding: 20px 0 0;
`;

const FirstCol = styled.div`
  grid-column: 1 / span 4;
`;

const LastCol = styled.div`
  grid-column: 5 / span 6;
`;

const RowGrid = styled.div` 
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--brand-white);
  }

  ${media.large`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 22px;
  `}
`

const GradientMask = styled.div`
  position: absolute;
  display: block;
  background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%);
  width: 100%;
  left: 0;
  height: 220%;
  top: 0px;
  transform: translate(0,-55%);
  z-index: 2;
`

const AboutUnconventional = ({smoother}) => {

  React.useLayoutEffect(()=> {
    let tl;
    let mm = gsap.matchMedia();
    tl = gsap.timeline({
      scrollTrigger: {
          trigger: "#about-unconv-wrapper",
          start: "top 100%",
          end: "+=800",
          scrub: true,
          markers: false
      }
    });

    tl
    .to('#about-gradient-mask', {
      duration: 1,
      y: "50%",
    })
    
    if(smoother) {
      

      mm.add("(min-width: 900px)", () => {
        
        const splitOur = new SplitText("#about-our-copy", { type: "words,chars" });
        const charsOur = splitOur.chars;


        charsOur.forEach((char, i) => {
            smoother.effects(char, { speed: 1, lag: (i + 1) * 0.008 });
        });

        return () => { // optional
          splitOur.revert();
        };
  
      })

    }
    
    return () => {
      if(tl) {
        tl.kill();
      }

    }
  },[smoother]);


  return (
    <Wrapper id="about-unconv-wrapper">
      <Grid>
        <BorderCol/>
      </Grid>
      <Grid>
        <Col>
          <GradientMask id="about-gradient-mask"/>
          <MobileBigCopy>
            Our unconventional process generates unconventional outcomes.
          </MobileBigCopy>
          <BigCopy id="about-our-copy">
            Our unconventional process<br/>generates unconventional<br/>outcomes.
          </BigCopy>
        </Col>
      </Grid>
    </Wrapper>
  )
}

export default AboutUnconventional;
